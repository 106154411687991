/** 
 * VR带看
 */
<template>
  <v-card class="VRSceneShow">
    <v-banner
        single-line
        height="400"
        class="banner"
    >
      <v-img
          src="https://h5.ophyer.cn/official_website/banner/banner_VR400.jpg"
          height="100%"
          :transition="false"
          :class="imgLoad ? 'v-image__image--animation':''">
        <template v-slot:placeholder>
          <v-row
              class="fill-height ma-0"
              align="center"
              justify="center"
          >
            <v-progress-circular
                indeterminate
                size="80"
                color="red lighten-5"
            ></v-progress-circular>
          </v-row>
        </template>
        <v-container color="transparent" class="pa-0 max-width-1200" style="margin-top: 150px;">
          <v-card color="transparent" flat>
            <v-card-title class="white--text pa-0 font-size-50 font-weight-bold font-size-banner-title">
              VR带看
            </v-card-title>
            <v-card-title class="white--text pa-0 mt-7 font-size-banner-tip">
              实景复刻，语音互动，打破空间距离的限制，让导览更高效
            </v-card-title>
            <!-- <v-card-actions class="pa-0 mt-16">
              <v-btn
                  rounded
                  width="160"
                  height="50"
                  style="#0568FD"
                  class="font-size-20"
              >
                <span style="color:#2571FF;">立即咨询</span>
              </v-btn>
            </v-card-actions> -->
          </v-card>
        </v-container>
      </v-img>
    </v-banner>  
    <!-- VR产品特色 -->
    <v-card flat class="product-part1 wrap">
      <div class="public-title">
        <p>FEATURE OF PRODUCTT</p>
        <div>
          <span>产品特色</span>
        </div>
      </div>
      <v-row class="ma-0 mt-16">
        <v-col cols="3" class="pa-0" v-for="n in data.wrap1.list" :key="n.title">
          <v-card flat>
              <div class="d-flex justify-center">
                <v-img 
                  :src="n.src" 
                  max-width="120" min-width="120" max-height="120" min-height="120"
                  style="border-radius:5px"
                ></v-img>
              </div>
            <v-card-title class="tit  mt-7 pa-0 " v-text="n.title"></v-card-title>
            <v-card-text class="text text-align-center pa-0 mt-5 " v-text="n.text"></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <!-- VR房屋带看 -->
    <div class=" wrap">
      <v-container>
        <div class="public-title" style="margin-top:100px">
          <p>VR HOUSE WITH A LOOK</p>
          <div>
            <span>VR房屋带看</span>
          </div>
        </div>
        <v-row class="pic1 pt-11" >
          <v-img contain width="100%" height="460" src="@/assets/images/service/v1.png"></v-img>
        </v-row>
      </v-container>
    </div>
    <!-- VR景区带看 -->
    <div class="wrap">
      <v-container class="mb-4">
        <div class="public-title">
          <p>VR HOUSE WITH A LOOK</p>
          <div>
            <span>VR景区带看</span>
          </div>
        </div>
        <div style="height:52px;"></div>
        <v-row class="pic1">
          <v-img contain width="100%" height="460" src="@/assets/images/service/vrScene2.png"></v-img>
        </v-row>
      </v-container>
    </div> 
    <!-- VR产品优势 -->
    <v-container class="product-part2 wrap mb-15">
      <div class="public-title" style="margin-top:50px">
        <p>PRODUCT ADVANTAGES</p>
        <div>
          <span>产品优势</span>
        </div>
      </div>
      <v-container>
        <v-row class="ma-0 mt-10">
          <v-col v-for="item in data.wrap2.content" :key="item.id" cols="4">
            <v-card class="px-8" flat>
              <div class="d-flex justify-center">
                <v-img 
                  :src="item.img" 
                  max-width="120" min-width="120" max-height="120" min-height="120"
                  style="border-radius:5px"
                ></v-img>
              </div>
              <v-card-title class="tit  mt-7 pa-0" v-text="item.cardTitle"></v-card-title>
              <v-card-text  class="text text-align-center pa-0 mt-5 font-size-14" v-text="item.h2"></v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-container>
  </v-card>
</template>

<script>
import contentData from '@/static/contentData.js'
export default {
  name: "VRSceneShow",
  data(){
    return{
      data:contentData.VRSceneShow,
      bannerLoad: false,
      imgLoad: false,
      tab:null,
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.public-title{
  height: 39px;
  margin-bottom: 0px;
}
.pic1{
  max-width: 1200px;
  margin: 0 auto;
}
.warp1 {
  .box {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    flex-direction: column;
    text-align: center;
    position: relative;
    img {
      width: 120px;
      height: 120px;
      border-radius: 10px;
    }
  }
}
.product-part1{
  max-width: 1200px;
  margin: 0 auto;
  img{
    width: 120px;
    height: 120px;
    border-radius: 5px;
  }
  .text{
    width: 266px;
    margin: 0 auto;
  }
  .tit{
    font-weight: 500;
    font-size: 20;
    justify-content: center;
    color: #333;
  }
}
.product-part2{
  max-width: 1200px;
  margin: 0 auto;
  img{
    width: 120px;
    height: 120px;
    border-radius: 5px;
  }
  .text{
    width: 266px;
    margin: 0 auto;
  }
  .tit{
    font-weight: 500;
    font-size: 20;
    justify-content: center;
    color: #333;
  }
}

</style>